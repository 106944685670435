<template>
  <div class="col-11 col-md-9 col-lg-8 col-xl-7 col-xxl-7 p-0">
    <Summary :details="value" :sadaqahUserDonatingType="sadaqahUserDonatingType" @goTo="goTo" />
    <div class="row mx-0 justify-content-center">
      <div class="col-10 my-4 text-center divider">
      </div>
    </div>
    <SetReference v-model:reference="value.bankAccountDetails.reference" />
    <AcceptTerms v-model:acceptTerms="acceptTermsValue" />
  </div>
</template>
<script>
import { defineAsyncComponent } from 'vue'

export default {
  name: 'Review Donation',
  props: ['modelValue', 'sadaqahUserDonatingType', 'acceptTerms'],
  emits: ['update:modelValue', 'update:acceptTerms', 'goTo'],
  components: {
    Summary: defineAsyncComponent(() => import('./views/Summary.vue')),
    SetReference: defineAsyncComponent(() => import('./views/SetReference.vue')),
    AcceptTerms: defineAsyncComponent(() => import('./views/AcceptTerms.vue'))
  },
  computed: {
    value: {
      get () {
        return this.modelValue
      },
      set (val) {
        this.$emit('update:modelValue', val)
      }
    },
    acceptTermsValue: {
      get () {
        return this.acceptTerms
      },
      set (val) {
        this.$emit('update:acceptTerms', val)
      }
    }
  },
  methods: {
    goTo (val) {
      console.log('Review Donation goTo', val)
      this.$emit('goTo', val)
    }
  }
}
</script>
<style scoped>
.divider {
  border-bottom: 2px solid var(--green-color-dark);
}
</style>
